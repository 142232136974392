<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import WebsiteSearch from "@/components/nestedSearch/nested-search.vue";

import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader,
        WebsiteSearch
    },

    data() {
        return {
            title: "Meta Data",
            items: [],
            websiteMetaDataItems: [],
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId'
        ])
    },

    methods: {
        handleOnPageSelected(pageInfoObj) {
            var pageId = pageInfoObj.id;

            if (typeof pageInfoObj.metaData !== 'undefined' && pageInfoObj.metaData !== null && typeof pageInfoObj.metaData.status !== 'undefined') {
                this.$router.push({ name: "metadata-edit-page", params: { websiteId: `${this.selectedWebisteId}`, pageId: `${pageId}`, metaDataId: `${pageInfoObj.metaData.id}`, featureId: `${pageInfoObj.metaData.featureId}`, path: `${pageInfoObj.path}` } });
            } else {
                var featureId = null;
                if ((typeof pageInfoObj.metaData !== 'undefined' && pageInfoObj.metaData !== null && typeof pageInfoObj.metaData.featureId !== 'undefined')) {
                    featureId = pageInfoObj.metaData.featureId;
                }

                if (featureId === null) {
                    featureId = -1;
                }

                this.$router.push({ name: "metadata-page", params: { websiteId: `${this.selectedWebisteId}`, pageId: `${pageId}`, path: `${pageInfoObj.path}`, featureId: featureId } });
            }
        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                        <p class="
                          text-uppercase
                          fw-bold
                          text-truncate
                          mb-0
                        ">
                            Create or Edit Webpage Metadata
                        </p>
                    </div>

                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <WebsiteSearch :websiteId="selectedWebisteId" @onPageSelected="handleOnPageSelected" />
                            </div>
                        </div>
                        <!-- end card body-->
                    </div>

                </div>
            </div>
        </div>
    </Layout>
</template>